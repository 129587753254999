var Aneox;
(function(Aneox) {
    'use strict';

    var App = (function() {

        var isTouchDevice;
        var headerNavVisibility = false;
        var currentLocale;
        var currentNode;
        var currentController;
        var navMenuVisibility = false;
       

        function App(fonts, locale) {
            this.fonts = fonts;
            this.locale = locale;
            //this.router = router;
            //this.analytics = analytics;
            this.controllers = {
                //home: homeController,
                //company: companyController,
                //vineyard: vineyardController,
                //vineyardDetails: vineyardDetailsController,
                //producers: producersController,
                //contact: contactController,
                //legal: legalController
            };

                lightbox.option({
                    'resizeDuration': 200,
                    'wrapAround': true,
                    'alwaysShowNavOnTouchDevices': true,
                    'albumLabel': "Image %1 sur %2"
                })

            var self = this;

           var md = new MobileDetect(window.navigator.userAgent);
            Aneox.Data.Config.runtime = {
                isTouchDevice: 'ontouchstart' in document.documentElement,
                mobile: md.mobile(),
                supportsScrollEvents: !md.mobile() || (md.is('iOS') && md.is('Safari') && md.version('Safari') > 8)
            };

            lazySizesConfig.preloadAfterLoad = true;
                
            /*$('.block-limg-rct .background-cover').matchHeight({
                target: $('.block-limg-rct')
            });*/

            // Routes that are fetched from CMS
            /*router.deferredRoutes = function(callback) {
                _.forEach(Aneox.Data.Data.producers, function(producer) {
                    Aneox.Data.Nodes.nodes.push({
                        id: producer.id,
                        type: 'producer-page',
                        locales: [{
                            locale: 'fr',
                            description: "Bienvenue au Mouchonnat. Découvrez cet appartement d’hôte de luxe en plein centre historique de Beaune au coeur de la Bourgogne.",
                            path: '/vignerons/' + _.kebabCase(producer.name),
                            title: 'Mouchonnat - Accueil'
                        }]
                    });
                });

                callback();
            };*/


         /*   this.router.bind('pageChange', function(path, node, nodeLocale) {
                if (_.get(currentNode, 'id') === node.id) return;

                self.locale.setLocale(nodeLocale.locale);

                var rootNodeId, controllerId;

                // Enter/Leave animations
                if (_.get(currentNode, 'id') !== node.id) {
                    switch (_.get(currentNode, 'type')) {
                        case 'vineyard-details':
                            self.controllers.vineyardDetails.leave();
                            break;
                        case 'producer-page':
                            if (node.type === 'producer-page') break;
                            self.controllers.producers.leave();
                            break;
                        case 'basic-page':
                            self.controllers[currentNode.id].leave();
                            break;
                    }

                    switch (node.type) {
                        case 'vineyard-details':
                            self.controllers.vineyardDetails.enter(node.id);
                            rootNodeId = 'vineyard';
                            controllerId = 'vineyardDetails';
                            break;
                        case 'producer-page':
                            if (_.get(currentNode, 'type') !== 'producer-page') self.controllers.producers.enter(node.id);
                            else self.controllers.producers.swap(node.id);
                            rootNodeId = 'producers';
                            controllerId = 'producers';
                            break;
                        default:
                            self.controllers[node.id].enter();
                            rootNodeId = node.id;
                            controllerId = node.id;
                            break;
                    }
                }

                // Update active menu item
                $('#nav-menu ul li').removeClass('active');
                $('#nav-menu ul li a[router-href="' + rootNodeId + '"]').parent().addClass('active');

                currentNode = node;
                currentController = self.controllers[controllerId];
            });*/

           /* this.locale.bind('localeChange', function(locale) {
                if (currentLocale === locale) return;

                $('#aside-languages li').removeClass('active');
                $('#aside-languages li a[router-locale="' + locale + '"]').parent().addClass('active');

                currentLocale = locale;

                setTimeout(handleWindowResize);
            });*/

            // Window resize handling
            var handleWindowResize = function() {
                var screenWidth = _.get(window, 'screen.width', 0);
                var screenHeight = _.get(window, 'screen.height', 0);
                var maxScreenSize = Math.max(screenWidth, screenHeight);
                var screenOrientation = _.get(window, 'orientation', 0);
                var isPortrait = screenOrientation === 0 || screenOrientation === 180 || screenOrientation === -180;
                var windowHeight = $(window).height();
                
                var elementMenu = $('header');
                var menuHeight = _.parseInt(elementMenu.css('height'));
                var openingHeight = windowHeight - menuHeight;
                var halfOpeningHeight = openingHeight / 2;
                
                $('.full-height').height(openingHeight);
                $('#main-entreprise .full-height').height(openingHeight - 70);
                
                if(!Aneox.Data.Config.runtime.mobile){
                    if(windowHeight > 710){
                    $('#valeurs-esprit .block-fond-gold, #valeurs-vision .block-fond-black').height(halfOpeningHeight);    
                    }else{
                        $('#valeurs-esprit .block-fond-gold, #valeurs-vision .block-fond-black').height(290);
                    }
                }
                
                
                
                
                
                //var openingHeight = windowHeight - (_.parseInt($('header').css('height')) + _.parseInt($('header').css('padding-top'));
                //console.log(openingHeight+'--'+_.parseInt($('header').css('height'))+'--'+_.parseInt($('header').css('padding-top'));
                //$('#home-ouverture section').height(openingHeight);
                
                /*if (Aneox.Data.Config.runtime.mobile && (maxScreenSize <= 768 || (isPortrait && screenWidth <= 768))) {
                    window.location.href = window.location.host.indexOf('bhf-groupe.com') === -1 ? '/mobile.bhf-groupe.com/' : 'http://mobile.bhf-groupe.com/';
                }*/
                
                /*$('.block-limg-rct .background-cover').matchHeight({ remove: true });
                $('.block-limg-rct .background-cover').matchHeight({
                    target: $('.block-limg-rct .test')
                });*/
                
                /*if(maxScreenSize >= 768){
                    var test = $('.block-limg-rct article').height();
                    console.log(test);
                    $('.block-limg-rct .small-img').height(test);
                    
                    var tabtest = [];
                    
                    _.forEach($('.block-limg-rct'), function(element){
                        tabtest.push(Array({'obj':$(element)},  {'oHeight':$('.block-limg-rct article').height()}));
                    });
                    
                    console.log(tabtest);
                    
                    
                }*/
                
               

                _.forEach($('[screen-height]'), function(element) {
                    var heightElement = $(element);
                    var topMargin = _.parseInt(heightElement.css('margin-top'));
                    var bottomMargin = _.parseInt(heightElement.css('margin-top'));
                    var height = windowHeight - topMargin - bottomMargin;

                    //heightElement.innerHeight(height);
                });

                //if (currentController && currentController.handleWindowResize) currentController.handleWindowResize.call(currentController);
            };
            
            var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, { leading: false, trailing: true });
            $(window).resize(throttledWindowResizeHandler);
            Visibility.change(throttledWindowResizeHandler);
            this.fonts.bind('fontsActive', handleWindowResize);
            handleWindowResize();
            
            // Scroll handling
            $(window).on('scroll', function (event) {
                App.prototype.swapMenu();
                
                
                //if (currentController && currentController.handleScroll) currentController.handleScroll.call(currentController, event); 
            });
            
            App.prototype.swapMenu = function(){
                
                var scrollTopElement = /WebKit/.test(navigator.userAgent) ? $(document.body) : $("html");
                
                if (scrollTopElement.scrollTop() > 180) {
                    if(this.headerNavVisibility !== true){
                        TweenMax.killTweensOf("#header-nav");
                        TweenMax.to("#header-nav", 0.5, { y: 70, force3D: true });
                        this.headerNavVisibility = true;
                    }
                }else{
                    if (this.headerNavVisibility === true) {
                        TweenMax.killTweensOf("#header-nav");
                        TweenMax.to("#header-nav", 0.5, { y: 0, force3D: true });
                        this.headerNavVisibility = false;
                    }
                }
            }
            
            App.prototype.swapMenu();
            
            
            App.prototype.toggleNavMenu = function () {
                TweenMax.killTweensOf('#mobile-menu-layer');

                if (!self.navMenuVisibility) {
                
                    
                    TweenMax.set('#mobile-menu-layer', { display: 'block', height: '100%' });
                    TweenMax.from('#mobile-menu-layer', 0.5, { height: 0 });
                    TweenMax.to('#mobile-menu-layer ul', 1, { opacity: 1 });
                    
                } else {
                    TweenMax.to('#mobile-menu-layer ul', 0.5, { opacity: 0 });
                    TweenMax.to('#mobile-menu-layer', 1, { height: 0, display: 'none' });
                    
                    //TweenMax.set('#mobile-menu-layer', { display: 'none' });
                    
                }

                self.navMenuVisibility = !self.navMenuVisibility;
            };

            App.prototype.hideNavMenu = function () {
                if (!self.navMenuVisibility) return;

                TweenMax.killTweensOf('#nmobile-menu-layer');

                TweenMax.to('#mobile-menu-layer', 0.5, { height: 0, display: 'none' });
                TweenMax.set('#nav-menu-background', { display: 'none' });

                self.navMenuVisibility = !self.navMenuVisibility;
            }
            

            // Wheel handling
            // $(window).on('mousewheel', function (event) {

            // });

            // Touch handling
            // if (Aneox.Data.Config.runtime.isTouchDevice) {
            //     var mc = new Hammer.Manager(document.body);
            //     mc.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }));
            //     mc.on('swipeleft swiperight', function (event) {
            //     });
            // }

            // Keyboard handling
            //   $(window).keydown(function (event) {
            //   });

        };

        return App;
    })();

    Aneox.App = App;
})(Aneox || (Aneox = {}));