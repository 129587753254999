var Aneox;
(function(Aneox) {
    'use strict';

    // Lazysizes - add simple support for background images
    document.addEventListener('lazybeforeunveil', function(e) {
        var bg = e.target.getAttribute('data-bg');
        if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });

    window.rootPath = _.trim(_.get(window, 'rootPath', ''));

    // Main bootlejs init
    var bottle = new Bottle();

    bottle.service('Library.Fonts', Aneox.Library.Fonts);
    bottle.service('Library.Locale', Aneox.Library.Locale);
    //bottle.service('Library.Router', Aneox.Library.Router);
    //bottle.service('Library.Analytics', Aneox.Library.Analytics, 'Library.Router');

    bottle.service('Animations', Aneox.Animations);

    //bottle.service('Controllers.Home', Aneox.Controllers.Home, 'Animations', 'Library.Locale', 'Library.Router');
    //bottle.service('Controllers.Company', Aneox.Controllers.Company, 'Animations', 'Library.Locale', 'Library.Router');
    //bottle.service('Controllers.Vineyard', Aneox.Controllers.Vineyard, 'Animations', 'Library.Locale', 'Library.Router');
    //bottle.service('Controllers.VineyardDetails', Aneox.Controllers.VineyardDetails, 'Animations', 'Library.Locale', 'Library.Router');
    //bottle.service('Controllers.Producers', Aneox.Controllers.Producers, 'Animations', 'Library.Locale', 'Library.Router');
    //bottle.service('Controllers.Contact', Aneox.Controllers.Contact, 'Animations', 'Library.Locale', 'Library.Router');
    //bottle.service('Controllers.Legal', Aneox.Controllers.Legal, 'Animations', 'Library.Locale', 'Library.Router');

    bottle.service('App', Aneox.App,
        'Library.Fonts', 'Library.Locale');

    window.app = bottle.container.App;

})(Aneox || (Aneox = {}));